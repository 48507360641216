import { DownArrowIcon } from '@/assets/svgExports/DownArrowIcon';
import { DropwdownContainer } from './Dropdown.styled';

const Dropdown = ({
  dropDownData,
  handler,
  rightPx,
  logoSize,
  themeConfig,
  handleClose,
}) => {
  return (
    <DropwdownContainer
      logoSize={logoSize}
      className="dropdown-container"
      style={{ right: rightPx || '' }}
      themeConfig={themeConfig}
    >
      {dropDownData?.map((data) =>
        data?.name?.length > 0 ? (
          <div
            onClick={() => {
              handler(data);
              handleClose && handleClose();
            }}
            key={data?.id}
            style={{
              justifyContent: rightPx ? 'flex-end' : 'flex-start',
              textAlign: rightPx ? 'right' : 'left',
            }}
            className="nav-links"
          >
            {' '}
            {data?.name} {data?.isDropdown && <DownArrowIcon color="black" />}{' '}
          </div>
        ) : null
      )}
    </DropwdownContainer>
  );
};

export default Dropdown;
