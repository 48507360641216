export const RightArrowIconTransparent = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || '24px'}
      height={props?.width || '24px'}
      viewBox="0 0 24 24"
      fill="currentColor"
    >
      <g clip-path="url(#clip0_3272_805)">
        <path
          d="M8.59009 16.59L13.1701 12L8.59009 7.41L10.0001 6L16.0001 12L10.0001 18L8.59009 16.59Z"
          fill={props?.color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3272_805">
          <rect width="24" height="24" fill={props?.color} />
        </clipPath>
      </defs>
    </svg>
  );
};
