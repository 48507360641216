import styled from 'styled-components';
import { hexToRgba } from '../../utils/common';

export const DropwdownContainer = styled.div`
  position: absolute;
  display: flex;
  width: 14.5rem;
  flex-direction: column;
  top: 32px;
  z-index: 5;
  max-height: 200px;
  overflow-y: auto;

  & div {
    padding: 0.75rem 1rem;
    text-align: left;
    border-bottom: 1px solid
      ${(props) => hexToRgba(`${props.themeConfig.borderColor}`, 0.1)};
  }
`;
