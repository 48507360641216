export const DownArrowIcon = (props) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={props?.width || '20px'}
      height={props?.height || '20px'}
      viewBox="0 0 20 20"
      fill="currentColor"
    >
      <g clip-path="url(#clip0_3665_9925)">
        <path
          d="M6.175 7.15839L10 10.9751L13.825 7.15839L15 8.33339L10 13.3334L5 8.33339L6.175 7.15839Z"
          fill={props?.color}
        />
      </g>
      <defs>
        <clipPath id="clip0_3665_9925">
          <rect
            width={props?.width || '20px'}
            height={props?.height || '20px'}
            fill={props?.color}
            transform="translate(20) rotate(90)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
